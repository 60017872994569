import React from 'react'
import Menu from '../components/menu-component'

// import * as _ from 'lodash';

import Layout from '../components/layout'
import './index.css';

const IndexPage = (props) => {
  return (
    <Layout>
      <h1>Skills and Resume</h1>
      <Menu />
      <div>
          <div>
              <h3>
                Full Stack Web Developer focusing on Frontend React.js
              </h3>
          </div>
      </div>
    </Layout>
  )
}

export default IndexPage